import React from 'react'
import '../Additionalcss/Contactpage.css'
import {Fade} from 'react-reveal';
function Contactpage() {
    return (
        <div>
              <div className=" container-fluid full-contact">
              <div className="contactus-page">
                 <Fade up>
                     <div className="container">
                 <div className="row contactus-row">
                     {/* <div className="col-lg-4">
                         <div className="contactnew-card">
                         <span className="contact-icon"><i class="fa-solid fa-location-dot"></i></span>
                         <p className="icon-firsttxt">Bangaluru</p>
                         <p className="icon-subtxt">80, 6th Main, 2nd Cross, 3rd Phase, JP Nagar, Bengaluru, Karnataka 560078</p>
                         
                         </div>
                     </div> */}
                     {/* <div className="col-lg-2"></div> */}
                     <div className="col-lg-4 ">
                     <div className="contactnew-card">
                     <span className="contact-icon"><i class="fa-solid fa-location-dot"></i></span>
                         <p className="icon-firsttxt">Innovation lab</p>
                         <p className="icon-subtxt">No.30/6 Karthik Tower, 2nd Floor, Church 7th Street, T.T. Nagar
                       Karaikudi, Tamilnadu 630001</p>
                         {/* <p className="icon-subtxt">Phone: +91-4565291469 <br/>Email: contact@kdpcorp.com</p> */}
                         </div>
                     </div>
                     <div className="col-lg-4 ">
                     <div className="contactnew-card">
                     <span className="contact-icon"><i class="fa-solid fa-location-dot"></i></span>
                         <p className="icon-firsttxt">USA</p>
                         <p className="icon-subtxt">1281 Win Hentschel Blvd. Suite 1300, West Lafayette, IN 47906</p>
                         {/* <p className="icon-subtxt">Email: contact@kdpcorp.com</p> */}
                         </div>
                     </div>
                     <div className="col-lg-4 ">
                         <div className="contactnew-card">
                     <div className="inner-imgtxt">
               <div className="arange-txt">
               <span className="innertxt-head">Contact Us</span><br/><br/>
               {/* <p className="innertxt-content"><i class="fas fa-phone"></i>&nbsp;&nbsp;Phone: +91 4565 - 224423</p> */}
               <p className="innertxt-last"><i class="fas fa-envelope"></i>&nbsp;&nbsp;Email: contact@kdptec.com</p>
               </div>
               <div className="inner-iconbox">
                   <p className="inner-contacticon">
                       {/* <span className="watsapcon"><i class="fa-brands fa-whatsapp"></i></span>&nbsp;&nbsp;  */}
                   <span className="instacon"><a className="instacon" href="https://www.instagram.com/kdp_tec/" target="_blank"><i class="fa-brands fa-instagram"></i></a></span>&nbsp;&nbsp;
                   <span className="fbcon"><a className="fbcon" href="https://www.facebook.com/people/KDP-Tec/100090136518653/?amp%3Bis_tour_completed=true"target="_blank"><i class="fa-brands fa-facebook-square"></i></a></span> &nbsp; 
                   <span className="linkcon"><a className="linkcon" href="https://www.linkedin.com/in/kdp-tec-3bb75a256/"target="_blank"><i class="fa-brands fa-linkedin-in"></i></a></span></p>
               </div>
           </div>
           </div>
                     </div>
                     </div>
                     </div>
                     {/* <div className="row">
                         <div className="col-lg-1"></div>
                     <div className="col-lg-5 ">
                    <div className="inner-imgtxt">
               <div className="arange-txt">
               <span className="innertxt-head">Contact Us</span><br/>
               <p className="innertxt-content"><i class="fas fa-phone"></i>&nbsp;&nbsp;Phone : +91-4565291469</p><br/>
               <p className="innertxt-last"><i class="fas fa-envelope"></i>&nbsp;&nbsp;Email : contact@kdptec.com</p>
               </div><br/>
               <div className="inner-iconbox">
                   <p className="inner-contacticon">
                      
                   <span className="instacon"><i class="fa-brands fa-instagram"></i></span>&nbsp;&nbsp;
                   <span className="fbcon"><i class="fa-brands fa-facebook-square"></i></span> &nbsp;&nbsp;
                   <span className="linkcon"><i class="fa-brands fa-linkedin-in"></i></span></p>
               </div>
           </div>

                     </div>
                     <div className="col-lg-5">
 <div className="innercon-form">
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10">
                    <input type="text" class="form-control formchange"id="inputbox" placeholder="Name"/>
                    </div>
                    <div className="col-lg-1"></div>
                </div><br/>
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10">
                    <input type="email" class="form-control formchange"id="inputbox" placeholder="Email"/>
                    </div>
                    <div className="col-lg-1"></div>
                </div>
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10">
                    <textarea className="form-botomtext" id="textfull" placeholder="Message"></textarea><br/>
                    <div className="center-btn">               
                    <button type="button" className="cntct-btn">Submit</button></div>
                    </div>
                    <div className="col-lg-1"></div>
                </div>

            </div>



                     
                     </div>
                     <div className="col-lg-1"></div>
                     </div> */}
                     <br/>
                     
                 </Fade>
                 
             </div> 
    
   </div>
        </div>
    )
}

export default Contactpage;
