import React from 'react'
import {Fade,Zoom,Flip} from 'react-reveal';
import Footer from '../Components/Footer'
import { Carousel } from 'react-bootstrap'
import donation from '../image/donation.png'
import donation_2024 from '../image/donation_2024.png'
import lifetwo from '../image/lifetwo.png'
import lifethree from '../image/lifethree.png'
import lifefour from '../image/lifefour.png'
import Header from '../Components/Header'
// import lifeone from '../image/lifeone.png'

 function Givingback() {
    return (
        <div>
            <Header/>
            <div className="firstabout" id="givetop"></div>
             <div className="container givingback">
 <div className="above-content" id="give">
<p className="above-head">Giving Back</p></div>
<div className="givingcnt">
<p className="above-text">"Making a difference". That's the mantra by which we operate. We belive that through our actions if we enhance</p>
<p className="above-text">even one person's life then our goal has been achieved</p>
<p className="above-text">We are the most fortunate' and with that understanding we serve the less fortunate by providing support in any</p>
<p className="above-text">form or shape possible</p>

  </div><br/>
  <Carousel fade prevLabel={false} nextLabel={false} indicators={false} interval={2000} className="last-carousel">
          
  <Carousel.Item   className="item">
    <Zoom>
    <img
      className="d-block w-100"
      src={donation}
      alt="First slide" id="boot-img"
    />
       </Zoom>             
  </Carousel.Item>
   <Carousel.Item  className="item">
     <Zoom>
    <img
      className="d-block w-100"
      src={donation_2024}
      alt="Second slide" id="boot-img"
    />
    </Zoom>

  </Carousel.Item >
   
  <Carousel.Item  className="item">
    < Zoom>
    <img
      className="d-block w-100"
      src={donation}
      alt="Third slide" id="boot-img"
    />
    </Zoom>
  </Carousel.Item> 
  <Carousel.Item  className="item">
     <Zoom>
    <img
      className="d-block w-100"
      src={donation_2024}
      alt="Second slide" id="boot-img"
    />
    </Zoom>

  </Carousel.Item >
  {/* <Carousel.Item  className="item">
    < Zoom>
    <img
      className="d-block w-100"
      src={blood}
      alt="fouth slide" id="boot-img"
    />
    </Zoom>
  </Carousel.Item>  */}
</Carousel>
 </div><br/>
 <Footer/>
        </div>
    )
}
export default Givingback;