import React,{ useEffect,useState }  from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import {Fade} from 'react-reveal';
// import Contactpage from '../Components/Contactpage';
import talkoneremove from '../image/talkoneremove.png'
function Talktous() {
    const[name,setName]=useState("")
    const[email,setEmail]=useState("")
    const[message,setMessage]=useState("")
    const[error,setError]=useState(false)

     async function handleSubmit(event){
        event.preventDefault();
       if(name.length==0){
       setError(true)
     }
     if (email.length==0){
       setError(true)
     }
     if (message.length==0){
      setError(true)
    }
          var Name=document.getElementById("names")
          var Email=document.getElementById("email")
          //localStorage.setItem("names", Name);
        //   localStorage.setItem('user', JSON.stringify(result.token));
         
        //   var Message=document.getElementById("mesg").value
          
        
          const data=new FormData(event.target);
           console.log("submit1");
          const url="https://apitest.kdptec.com/api/Contactpage/post";
        //   const url="https://localhost:44315/api/Contactpage/post";
          
            let user =(localStorage.getItem('user'));
        const accessToken=user;
            const option={
              method:"POST",
              headers:{
                //"Content-Type: "application/json",
                  // "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
              },
               body:data
          };
          const response = await fetch(url,option);
          const result = await response.json();
          if (result.status === "Success" ){
            alert(
                result.message);
        //        let user = JSON.parse(localStorage.getItem('user'));
        //     //const accessToken=user;
        //         // navigate('/login');
            }
            else{
           alert(
                result.message);
            }
          
      }
    return (
        <div>
            <Header/>
            {/* <div className="talkpage"> */}
                <div className="container-fluid talk-fluid">
                <div className="firstabout" id="talktop"></div>
            {/* <div className="container talkrow">
            <div className=" row">
                
                <div className="col-lg-6">
                    <img src={talkoneremove}className="talkimg"/>
                </div>
                <div className="col-lg-4">
              <div className="talktxt">Talk to<br/>Us!</div>
                </div>
                <div className="col-lg-2">
                    
                </div>
            </div>
            </div> */}
            <div className="about-head">Talk to Us</div>
            <div className=" container-fluid full-ncontact">
              <div className="container contactus-page">
                 <Fade down>
                 <div className="row contactus-row">
                     <div className="col-lg-2"></div>
                     {/* <div className="col-lg-4">
                         <div className="contactnew-card">
                         <span className="contact-icon"><i class="fa-solid fa-location-dot"></i></span>
                         <p className="icon-firsttxt">Bangaluru</p>
                         <p className="icon-subtxt">80, 6th Main, 2nd Cross, 3rd Phase, JP Nagar, Bengaluru, Karnataka 560078</p>
                         </div>
                     </div> */}
                     <div className="col-lg-4">
                     <div className="contactnew-card">
                     <span className="contact-icon"><i class="fa-solid fa-location-dot"></i></span>
                         <p className="icon-firsttxt">Innovation lab</p>
                         <p className="icon-subtxt">No.30/6 Karthik Tower,2nd Floor,Church 7th Street,T.T. Nagar
                        Karaikudi, Tamilnadu 630001</p>
                         </div>
                     </div>
                     <div className="col-lg-4">
                     <div className="contactnew-card">
                     <span className="contact-icon"><i class="fa-solid fa-location-dot"></i></span>
                         <p className="icon-firsttxt">USA</p>
                         <p className="icon-subtxt">1281 Win Hentschel Blvd. Suite 1300, West Lafayette, IN 47906</p>
                         </div>
                     </div>
                     <div className="col-lg-2"></div>
                 </div></Fade>
                 
             </div> 
    <div className="container"> 
      <Fade up>
          <div className="row">

          <div className="col-lg-2"></div>
          
          <div className="col-lg-4 cntctus">
            
           <div className="inners-imgtxt">
               <div className="arange-txt">
               <span className="innertxt-head">Contact Us</span><br/>
               {/* <p className="innertxt-content"><i class="fas fa-phone"></i>&nbsp;&nbsp;Phone : +91 4565 - 224423</p> */}
               <p className="innertxt-last"><i class="fas fa-envelope"></i>&nbsp;&nbsp;Email : contact@kdptec.com</p>
               </div>
               <div className="inner-iconsbox">
                   <p className="inner-contacticon">
                       {/* <span className="watsapcon"><i class="fa-brands fa-whatsapp"></i></span>&nbsp;&nbsp;  */}
                   <span className="instacon"><a className="instacon" href="https://www.instagram.com/kdp_tec/" target="_blank"><i class="fa-brands fa-instagram"></i></a></span>&nbsp;&nbsp;
                   <span className="fbcon"><a className="fbcon" href="https://www.facebook.com/people/KDP-Tec/100090136518653/?amp%3Bis_tour_completed=true"target="_blank"><i class="fa-brands fa-facebook-square"></i></a></span> &nbsp;
                   <span className="linkcon"><a className="linkcon" href="https://www.linkedin.com/in/kdp-tec-3bb75a256/"target="_blank"><i class="fa-brands fa-linkedin-in"></i></a></span></p>
               </div>
           </div>
          </div>
          
          <div className="col-lg-4 ">
            <div className="innercons-form">
                <form onSubmit={handleSubmit}>
                <div className="row">
                    {/* <div className="col-lg-1"></div> */}
                    <div className="col-lg-12">
                    <input type="text" name="Name" id="names"class="form-control formchanges" placeholder="Name" onChange={e=>setName(e.target.value)}/>
                    {error&&name.length<=0?
                   <p style={{padding:"5px",color:"red"}}>Name can't be empty</p>:''}
                    </div>
                    {/* <div className="col-lg-1"></div> */}
                </div><br/>
                <div className="row">
                    {/* <div className="col-lg-1"></div> */}
                    <div className="col-lg-12">
                    <input type="email" name="Email" id="email" class="form-control formchanges" placeholder="Email"  onChange={e=>setEmail(e.target.value)}/>
                    {error&&email.length<=0?
                    <p style={{padding:"5px",color:"red"}}>Email is required</p>:''}
                    </div>
                    {/* <div className="col-lg-1"></div> */}
                </div>
                <div className="row">
                    {/* <div className="col-lg-1"></div> */}
                    <div className="col-lg-12 butnsrow">
                    <textarea className="form-botomtext" name="Message" id="mesg" placeholder="Message" onChange={e=>setMessage(e.target.value)}></textarea>
                    {error&&message.length<=0?
                    <p style={{padding:"5px",color:"red"}}>Message can't be empty</p>:''}
                    <br/>
                    <div className="change-btn">
                    <button type="Submit" value="Submit" className="cntct-btns  btn2">Submit</button>
                    </div>
                    </div>
                    {/* <div className="col-lg-1"></div> */}
                </div>
                 </form>
            </div>
          </div>
         
          <div className="col-lg-2"></div>
    
      </div>
      </Fade>
   </div> 
   </div>
            {/* <div className="fulltalk"> */}
                
            {/* <Contactpage/> */}
            {/* </div> */}
            </div><br/>
        {/* </div> */}
            <Footer/>
        </div>
    )
}
export default  Talktous;